import { auth } from '../../firebase';
import ActionTypes from './constants';

export function signIn(email, password) {
  return dispatch => {
    auth
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        dispatch(userSignedIn(auth.currentUser));
      })
      .catch(error => {
        dispatch(authError(error));
      });
  };
}
export function signUp(email, password) {
  return dispatch => {
    auth
      .createUserWithEmailAndPassword(email, password)
      .then(authUser => {
        dispatch(userSignedUp(authUser));
      })
      .catch(error => {
        dispatch(authError(error));
      });
  };
}
export function signOut() {
  return dispatch => {
    auth
      .signOut()
      .then(() => {
        dispatch(userSignedIn(null));
      })
      .catch(error => {
        dispatch(authError(error));
      });
  };
}
function userSignedIn(authUser) {
  return {
    type: ActionTypes.SingIn,
    authUser,
  };
}
function authError(error) {
  return {
    type: ActionTypes.AuthError,
    error,
  };
}
function userSignedUp(newUser) {
  return {
    type: ActionTypes.SignUp,
    newUser,
  };
}
