import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  shouldComponentUpdate(nextProps, nextState) {
    return !(isEqual(this.props, nextProps) && isEqual(this.state, nextState));
  }
  componentWillReceiveProps(newProps) {
    if (newProps && newProps.storeShifts) {
      let allShifts = newProps.storeShifts;
      let filteredShift = allShifts.filter(obj => {
        return obj.isCurrent === true;
      });
      if (filteredShift.length > 0) {
        this.setState({
          isPickUpOpen: true,
        });
      } else {
        this.setState({
          isPickUpOpen: false,
        });
      }
      let filteredShift1 = allShifts.filter(obj => {
        return obj.isDeliveryOpen === true;
      });
      if (filteredShift1.length > 0) {
        this.setState({
          isDeliveryOpen: true,
        });
      } else {
        this.setState({
          isDeliveryOpen: false,
        });
      }
    }
  }

  getDeliveryText = () => {
    return 'Delivery Closed';
  };

  getPickupText = () => {
    return 'Pickup Closed';
  };
  render() {
    return (
      <header className='header'>
        <div className='container' style={{}}>
          <h1
            className={`header-title ${
              this.props.store ? '' : 'header-padding-top'
            }`}
          >
            Next Order
          </h1>
          {this.props.store && (
            <React.Fragment>
              {!this.state.isPickUpOpen && !this.state.isDeliveryOpen ? (
                <React.Fragment>
                  {this.props.storeShifts && this.props.storeShifts.length > 0 && (
                    <ul className='order-timing-list'>
                      <li className='blue'>STORE CLOSED</li>
                    </ul>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <ul className='order-timing-list'>
                    <li>
                      {!this.state.isPickUpOpen && this.getPickupText()}
                      {this.state.isPickUpOpen &&
                        `Pickup Time\u00A0\u00A0\u00A0${
                          this.props.orderSetup &&
                          this.props.orderSetup.pickUpTime
                            ? this.props.orderSetup.pickUpTime
                            : 20
                        }\u00A0\u00A0mins`}
                    </li>
                    <li className='blue'>
                      {!this.state.isDeliveryOpen && this.getDeliveryText()}
                      {this.state.isDeliveryOpen &&
                        `Delivery Time\u00A0\u00A0\u00A0${
                          this.props.orderSetup &&
                          this.props.orderSetup.deliveryTime
                            ? this.props.orderSetup.deliveryTime
                            : 40
                        }\u00A0\u00A0mins`}
                    </li>
                  </ul>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  orderSetup: PropTypes.any,
};

export default Header;
