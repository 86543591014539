import red_car from '../assets/images/red_car.svg';
import orange_car from '../assets/images/orange_car.svg';
import delivery_icon from '../assets/images/marker.svg';
const L = window.L;

const getCarIconFun = driver => {
  let car = '';
  if (driver.status === 2) car = orange_car;
  else if (driver.status === 1) car = red_car;

  let icon = L.icon({
    iconUrl: car,
    iconSize: [20, 32],
    iconAnchor: [10, 16],
  });
  return icon;
};

const getDeliveryIconFun = () => {
  let icon = L.icon({
    iconUrl: delivery_icon,
    iconSize: [30, 40], // size of the icon
    iconAnchor: [15, 20],
  });
  return icon;
};

const getStoreIconFun = storeIconurl => {
  let iconStore = L.icon({
    iconUrl: storeIconurl,
    iconSize: [30, 40], // size of the icon
    iconAnchor: [15, 40],
  });
  return iconStore;
};

export const getCarIcon = getCarIconFun;
export const getDeliveryIcon = getDeliveryIconFun;
export const getStoreIcon = getStoreIconFun;
