import ActionTypes from './constants';
export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.SingIn:
      return { ...state, authUser: payload.authUser };
    case ActionTypes.AuthError:
      return { ...state, error: payload.error };
    default:
      return state;
  }
};
