import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import localStorage from 'local-storage';
import { connect } from 'react-redux';
import { auth, firestore } from '../../firebase';
import { signIn, signOut } from './actions';
import { nextOrderConfig } from '../../configurations/storeSetup';

Sentry.init({
  dsn: 'https://1a5b1f7a857c4fc79bc865d4ffa112de@sentry.io/1417824',
  attachStacktrace: true,
  sendDefaultPii: true,
  environment: nextOrderConfig.environment,
  release: nextOrderConfig.release,
});

let logo =
  'https://s3-ap-southeast-2.amazonaws.com/lacampa/assets/next+order.svg';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoader: false,
    };
  }
  componentDidMount() {
    this.props.signOutHandler();
    auth
      .signInWithEmailAndPassword('instore.prod3@nextorder.com.au', 'Test@123')
      .then(() => {
        let obj = {
          storeId: 'xfQPNNr5jCcNwkIHkg9R',
          restaurantId: '7Tiko4jIUCTMNQSCrHTc',
          isActive: true,
          deviceId: this.guidGenerator(),
          deviceInfo: this.getDeviceInfo(),
          refreshCount: 0,
        };
        firestore
          .collection('LoggedInDevices')
          .add(obj)
          .then(doc => {
            localStorage.set('authDeviceId', doc.id);
            window.location =
              '/restaurant/instore?restaurant=' + 'xfQPNNr5jCcNwkIHkg9R';
          });
      })
      .catch(() => {
        this.setState({
          isLoader: false,
          error: { error: '', message: 'Some error occured' },
        });
      });
  }
  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }
  guidGenerator = () => {
    var S4 = function() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4();
  };

  getBrowserNameAndVersion = () => {
    var ua = navigator.userAgent,
      tem,
      M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return 'IE ' + (tem[1] || '');
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge?)\/(\d+)/);
      if (tem != null)
        return tem
          .slice(1)
          .join(' ')
          .replace('OPR', 'Opera')
          .replace('Edg ', 'Edge ');
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join(' ');
  };

  getOSNameAndVersion = () => {
    // system
    var unknown = '-';
    var nAgt = navigator.userAgent;
    var nVer = navigator.appVersion;
    var os = unknown;
    var clientStrings = [
      { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
      { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
      { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
      { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
      { s: 'Windows Vista', r: /Windows NT 6.0/ },
      { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
      { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
      { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
      { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
      { s: 'Windows 98', r: /(Windows 98|Win98)/ },
      { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
      { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
      { s: 'Windows CE', r: /Windows CE/ },
      { s: 'Windows 3.11', r: /Win16/ },
      { s: 'Android', r: /Android/ },
      { s: 'Open BSD', r: /OpenBSD/ },
      { s: 'Sun OS', r: /SunOS/ },
      { s: 'Linux', r: /(Linux|X11)/ },
      { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
      { s: 'Mac OS X', r: /Mac OS X/ },
      { s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
      { s: 'QNX', r: /QNX/ },
      { s: 'UNIX', r: /UNIX/ },
      { s: 'BeOS', r: /BeOS/ },
      { s: 'OS/2', r: /OS\/2/ },
      {
        s: 'Search Bot',
        r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
      },
    ];
    for (var id in clientStrings) {
      var cs = clientStrings[id];
      if (cs.r.test(nAgt)) {
        os = cs.s;
        break;
      }
    }

    var osVersion = unknown;

    if (/Windows/.test(os)) {
      osVersion = /Windows (.*)/.exec(os)[1];
      os = 'Windows';
    }

    switch (os) {
      case 'Mac OS X':
        osVersion = /Mac OS X (10[\.\_\d]+)/.exec(nAgt)[1];
        break;

      case 'Android':
        osVersion = /Android ([\.\_\d]+)/.exec(nAgt)[1];
        break;

      case 'iOS':
        osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
        osVersion =
          osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0);
        break;
    }

    return { os, osVersion };
  };

  getDeviceInfo = () => {
    let loginTime = new Date().getTime();
    let obj = {
      codeName: navigator.appCodeName,
      name: navigator.appName,
      version: navigator.appVersion,
      platform: navigator.platform,
      userAgent: navigator.userAgent,
      publicIp: '103.81.92.20',
      browserInfo: this.getBrowserNameAndVersion(),
      osInfo: this.getOSNameAndVersion(),
      loginTimestamp: loginTime,
    };
    return obj;
  };
  handleSubmit = () => {
    let authCode =
      this.refs[1].value +
      this.refs[2].value +
      this.refs[3].value +
      this.refs[4].value +
      this.refs[5].value +
      this.refs[6].value;
    this.setState({ isLoader: true });
    try {
      firestore
        .collection('StoreAuthCodes')
        .where('authCode', '==', authCode)
        .get()
        .then(querySnapshotMain => {
          var dataArrayMain = [];
          querySnapshotMain.forEach(doc => {
            dataArrayMain.push({ _id: doc.id, ...doc.data() });
          });
          if (dataArrayMain.length > 0) {
            firestore
              .collection('SystemUsers')
              .where('userRole', '==', '2')
              .where('type', '==', '1')
              .where('authCodeId', '==', dataArrayMain[0]._id)
              .get()
              .then(querySnapshot => {
                var dataArray = [];
                querySnapshot.forEach(doc => {
                  dataArray.push({ _id: doc.id, ...doc.data() });
                });
                if (dataArray.length > 0) {
                  auth
                    .signInWithEmailAndPassword(
                      dataArray[0].email,
                      dataArray[0].password
                    )
                    .then(() => {
                      let obj = {
                        storeId: dataArray[0].storeId,
                        restaurantId: dataArray[0].restaurantId,
                        isActive: true,
                        deviceId: this.guidGenerator(),
                        deviceInfo: this.getDeviceInfo(),
                        refreshCount: 0,
                      };
                      firestore
                        .collection('LoggedInDevices')
                        .add(obj)
                        .then(doc => {
                          localStorage.set('authDeviceId', doc.id);
                          window.location =
                            '/restaurant/instore?restaurant=' +
                            dataArray[0].storeId;
                        });
                    })
                    .catch(() => {
                      this.setState({
                        isLoader: false,
                        error: { error: '', message: 'Some error occured' },
                      });
                    });
                } else {
                  this.setState({
                    isLoader: false,
                    error: {
                      error: '',
                      message: 'Invalid authentication code',
                    },
                  });
                }
              });
          } else {
            this.setState({
              isLoader: false,
              error: {
                error: '',
                message: 'Invalid authentication code',
              },
            });
          }
        });
    } catch (error) {
      Sentry.captureException(error);
    }
  };
  _handleKeyPress = (e, field) => {
    if (e.keyCode === 8 || e.keyCode === 46) {
      if (field > 1) {
        this.refs[Number(field)].value = '';
        this.refs[Number(field) - 1].value = '';
        this.refs[Number(field) - 1].focus();
      }
    } else {
      this.setState({ isNumberSubmittedAgain: true });
      if (field < 6) {
        this.refs[Number(field) + 1].value = '';
        this.refs[Number(field) + 1].focus();
      } else if (field === 6) {
        setTimeout(() => {
          this.refs[Number(field)].blur();
        }, 100);
      }
    }
  };
  render() {
    return (
      <div className='main-content container-fluid'>
        <div className='splash-container'>
          <div
            style={{ padding: '50px' }}
            className='be-loading be-loading-active'
          >
            <div className='be-spinner'>
              <svg
                width='40px'
                height='40px'
                viewBox='0 0 66 66'
                xmlns='http://www.w3.org/2000/svg'
              >
                <circle
                  fill='none'
                  strokeWidth='4'
                  strokeLinecap='round'
                  cx='33'
                  cy='33'
                  r='30'
                  className='circle'
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    session: state.loginReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    signInHandler: (email, password) => dispatch(signIn(email, password)),
    signOutHandler: () => dispatch(signOut()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
