import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

//Next order Development
var config = {
  apiKey: 'AIzaSyCooeVoleaNRY2rxrYbNezP3x29-tEl65M',
  authDomain: 'next-order-prodenv.firebaseapp.com',
  databaseURL: 'https://next-order-prodenv.firebaseio.com',
  projectId: 'next-order-prodenv',
  storageBucket: 'next-order-prodenv.appspot.com',
  messagingSenderId: '81438711702',
  appId: '1:81438711702:web:b1d391a6172bd91e8987b7',
};

let app = firebase.initializeApp(config);

app.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION); //Set application's authentication session
export const firestore = firebase.firestore(); //Realtime database
const settings = { /* your settings... */ timestampsInSnapshots: true };
firestore.settings(settings);
export const auth = firebase.auth(); //Authentication
