const actionTypes = {
  FetchActiveDrivers: 'FETCH_ACTIVE_DRIVERS',
  OrderSetupSaved: 'ORDER_SETUP_SAVED',
  FetchActiveDriverShifts: 'FETCH_ACTIVE_DRIVER_SHIFTS',
  FetchActiveDriverRuns: 'FETCH_ACTIVE_DRIVER_RUNS',
  FetchActiveDriverRunOrders: 'FETCH_ACTIVE_DRIVER_RUN_ORDERS',
  FetchNotifications: 'FETCH_NOTIFICATIONS',
  FetchStore: 'FETCH_STORE',
  FetchAllShifts: 'FETCH_ALL_SHIFT',
};

export default actionTypes;
