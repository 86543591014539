import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import sortBy from 'lodash/sortBy';
import queryString from 'query-string';
import SlidingMarker from 'marker-animate-unobtrusive';
import { auth, firestore } from '../../firebase';
import DriverMap from '../../components/DriverMap';
import DriverStatusBoxes from '../../components/DriverStatusBoxes';
import DriverNotificationBoxes from '../../components/DriverNotificationBoxes';
import Header from '../../components/Header';
import '../../assets/css/bootstrap.css';
import '../../assets/css/style.css';
import {
  getActiveDrivers,
  getActiveDriverRuns,
  getActiveDriverRunOrders,
  getActiveDriverShifts,
  getNotifications,
  getStoreSetup,
  getStore,
  setListnerForAuthCode,
  selfLogin,
  deleteNotifications,
  getAllShifts,
} from './actions';
import { nextOrderConfig } from '../../configurations/storeSetup';

Sentry.init({
  dsn: 'https://1a5b1f7a857c4fc79bc865d4ffa112de@sentry.io/1417824',
  attachStacktrace: true,
  sendDefaultPii: true,
  environment: nextOrderConfig.environment,
  release: nextOrderConfig.release,
});

class InStore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationMapping: {},
    };
  }

  notificationMappingHandler = nameNotificationObj => {
    this.setState({
      notificationMapping: nameNotificationObj,
    });
  };

  componentDidMount() {
    SlidingMarker.initializeGlobally();
    auth.onAuthStateChanged(user => {
      if (!user) {
        let keyObj =
          window.location && window.location.search
            ? queryString.parse(window.location.search)
            : {};
        if (!keyObj.code) window.location = '/restaurant/login';
        else {
          this.props.selfLoginHandler(keyObj.code);
        }
      } else {
        firestore
          .collection('SystemUsers')
          .where('userRole', '==', '2')
          .where('type', '==', '1')
          .where('email', '==', user.email)
          .get()
          .then(querySnapshot => {
            if (querySnapshot.docs.length > 0) {
              querySnapshot.forEach(doc => {
                this.setState(
                  {
                    storeId: doc.data().storeId,
                    restaurantId: doc.data().restaurantId,
                    timeZone: doc.data().timeZone,
                  },
                  () => {
                    this.props.getStoreSetupHandler(this.state.storeId);
                    this.props.getStoreHandler(this.state.storeId);
                    this.props.getActiveDriversHandler(this.state.storeId);
                    this.props.getActiveDriverShiftsHandler(this.state.storeId);
                    this.props.getActiveDriverRunsHandler(this.state.storeId);
                    this.props.getActiveDriverRunOrdersHandler(
                      this.state.storeId
                    );
                    this.props.getNotificationsHandler(this.state.storeId);
                    this.props.getAllShiftsHandler(this.state.storeId);
                    this.props.setListnerForAuthCodeHandler();
                  }
                );
              });
            } else {
              window.location = '/restaurant/login';
            }
          });
      }
    });
  }

  componentWillReceiveProps(props) {
    this.setState({
      drivers: props.inStore.drivers,
      orderSetup: props.inStore.orderSetup,
      store: props.inStore.store,
      notifications: props.inStore.notifications,
      driverShifts: props.inStore.driverShifts,
      storeShifts: props.inStore.allShifts,
    });
    if (
      props.inStore.drivers &&
      props.inStore.driverRuns &&
      props.inStore.driverRunOrders &&
      props.inStore.driverShifts
    ) {
      let activeDrivers = JSON.parse(JSON.stringify(props.inStore.drivers));
      let activeRuns = JSON.parse(JSON.stringify(props.inStore.driverRuns));
      let activeOrders = JSON.parse(
        JSON.stringify(props.inStore.driverRunOrders)
      );
      let activeShifts = JSON.parse(JSON.stringify(props.inStore.driverShifts));
      activeRuns.map(m => {
        m.orders = activeOrders.filter(p => {
          return p.driverRunId === m._id;
        });
        return m;
      });
      activeDrivers.map(k => {
        let activeRunsArr = activeRuns.filter(p => {
          return p.shiftId === k.currentShiftId;
        });
        let myActiveShifts = activeShifts.filter(p => {
          return p._id === k.currentShiftId;
        });
        if (myActiveShifts.length > 0) {
          k.activeShift = myActiveShifts[0];
        }
        if (activeRunsArr.length > 0) {
          k.orders = activeRunsArr[0].orders;
        } else {
          k.orders = [];
        }
        if (k.status === 2) {
          k.statusName = 'Returning';
          k.class = 'orange';
        } else if (k.status === 1) {
          k.statusName = 'On Delivery';
          k.class = 'red';
        } else if (k.status === 0) {
          k.statusName = 'In Store';
          k.class = 'green';
        }
        k.userName = `${k.firstName} ${k.lastName}`;
        return k;
      });
      activeOrders = sortBy(activeDrivers, 'status');
      this.setState({
        activeDrivers,
      });
    }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }
  render() {
    return (
      <React.Fragment>
        <div className='wrapper'>
          <Header
            store={this.state.store}
            orderSetup={this.state.orderSetup}
            storeShifts={this.state.storeShifts}
          />
          <div className='section-order-information'>
            <div className='container'>
              {this.state.activeDrivers && (
                <DriverStatusBoxes
                  orderSetup={this.state.orderSetup}
                  activeDrivers={this.state.activeDrivers}
                  notificationMappingHandler={this.notificationMappingHandler}
                />
              )}
              {this.state.notifications && (
                <DriverNotificationBoxes
                  notifications={this.state.notifications}
                  deleteNotificationsHandler={
                    this.props.deleteNotificationsHandler
                  }
                  notificationMapping={this.state.notificationMapping}
                />
              )}
            </div>
          </div>
          <DriverMap
            store={this.state.store}
            drivers={this.state.activeDrivers}
            storeId={this.state.storeId}
          />
        </div>
      </React.Fragment>
    );
  }
}

InStore.propTypes = {};

function mapStateToProps(state) {
  return {
    inStore: state.inStoreReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getStoreSetupHandler: storeId => dispatch(getStoreSetup(storeId)),
    getActiveDriversHandler: storeId => dispatch(getActiveDrivers(storeId)),
    getActiveDriverRunsHandler: storeId =>
      dispatch(getActiveDriverRuns(storeId)),
    getActiveDriverShiftsHandler: storeId =>
      dispatch(getActiveDriverShifts(storeId)),
    getActiveDriverRunOrdersHandler: storeId =>
      dispatch(getActiveDriverRunOrders(storeId)),
    getNotificationsHandler: storeId => dispatch(getNotifications(storeId)),
    setListnerForAuthCodeHandler: () => dispatch(setListnerForAuthCode()),
    selfLoginHandler: authCode => dispatch(selfLogin(authCode)),
    deleteNotificationsHandler: notifications =>
      dispatch(deleteNotifications(notifications)),
    getStoreHandler: storeId => dispatch(getStore(storeId)),
    getAllShiftsHandler: storeId => dispatch(getAllShifts(storeId)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InStore);
