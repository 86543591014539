import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import Sound from 'react-sound';
import TimeAgo from 'react-timeago';
import arrived from '../../assets/sounds/Arrived.wav';
import DriverSignedOut from '../../assets/sounds/DriverSignedOut.wav';
import InternetIssueDriverDisconnected from '../../assets/sounds/InternetIssueDriverDisconnected.wav';
import OrderDelivered from '../../assets/sounds/OrderDelivered.wav';
import ReturningToStore from '../../assets/sounds/ReturningToStore.wav';
import { getCurrent60Timestamp } from '../../helpers/timeHelper';

class DriverNotificationBoxes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
      soundToPlay: '',
    };
  }
  componentDidMount() {
    if (this.props.notifications) {
      let notifications = [];
      let notificationsToBeDeleted = [];
      this.props.notifications.map((p, index) => {
        if (!this.isOlderThenOneMinute(p.dateTimestamp, p)) {
          let obj = p;
          obj.index = index;
          obj.class = this.getContainerClass(p);
          notifications.push(obj);
        } else {
          notificationsToBeDeleted.push(p);
        }
        return p;
      });
      this.setState(
        {
          notifications,
        },
        () => {
          let notificationsList = [...this.state.notifications];
          notificationsList.map(k => {
            k.class = `${k.class} show`;
            return k;
          });
          setTimeout(() => {
            this.setState({ notifications: notificationsList }, () => {
              this.props.deleteNotificationsHandler(notificationsToBeDeleted);
            });
            window.soundManager.setup({ debugMode: false });
          }, 1000);
          setInterval(this.removeOldNotifications, 60000);
        }
      );
    }
  }
  isOlderThenOneMinute = (timestamp, notification) => {
    if (notification.type === 1 || notification.type === 2) {
      if (notification.isOffline) return false;
      else return true;
    } else {
      if (getCurrent60Timestamp() > timestamp) return true;
      else return false;
    }
  };
  componentDidUpdate(prevProps) {
    if (
      prevProps.notifications.length !== this.props.notifications.length &&
      this.props.notifications.length === 0
    ) {
      this.setState({ notifications: [] });
    }
    if (
      prevProps.notifications.length !== this.props.notifications.length &&
      this.props.notifications.length !== 0
    ) {
      let notifications = cloneDeep(this.props.notifications);
      notifications.forEach(notification => {
        if (notification.type === 4) {
          if (this.props.notificationMapping[notification.name]) {
            notification.description = this.props.notificationMapping[
              notification.name
            ];
          }
        }
      });
      let firstArray = cloneDeep(notifications);
      let first = [firstArray.shift()];
      first[0].index = 0;
      first[0].class = `${this.getContainerClass(first[0])}`;
      notifications = notifications.slice(1);
      notifications.forEach((notification, index) => {
        notification.index = index;
        notification.class = this.isOlderThenOneMinute(
          notification.dateTimestamp,
          notification
        )
          ? `${this.getContainerClass(notification)}`
          : `${this.getContainerClass(notification)} show`;
      });
      this.setState({ notifications }, () => {
        let newNotifications = cloneDeep(this.state.notifications);
        this.setState(
          {
            notifications: newNotifications,
          },
          () => {
            setTimeout(() => {
              let inner = [...first, ...this.state.notifications];
              this.setState({ notifications: inner }, () => {
                setTimeout(() => {
                  let sound;
                  let animateNotification = cloneDeep(this.state.notifications);
                  if (animateNotification.length > 0) {
                    let classString = animateNotification[0].class;
                    classString = classString + ' show';
                    animateNotification[0].class = classString;
                    sound = this.getSound(animateNotification[0].type);
                  }
                  animateNotification.forEach(notification => {
                    if (notification.type === 4) {
                      if (this.props.notificationMapping[notification.name]) {
                        notification.description = this.props.notificationMapping[
                          notification.name
                        ];
                      }
                    }
                  });
                  this.setState({
                    notifications: animateNotification,
                    soundToPlay: sound,
                  });
                }, 1000);
              });
            }, 800);
          }
        );
      });
    }
    if (this.props.notifications.length === prevProps.notifications.length) {
      let newNotifs = cloneDeep(this.props.notifications);
      let oldNotifs = cloneDeep(prevProps.notifications);

      newNotifs.forEach((newNotif, index) => {
        oldNotifs.forEach((oldNotif, oldIndex) => {
          if (newNotifs[index].type === 2 && oldNotifs[index].type === 2) {
            if (
              newNotifs[index].isOffline === false &&
              oldNotifs[index].isOffline === true
            ) {
              this.props.deleteNotificationsHandler([newNotifs[index]]);
            }
          }
        });
      });
    }
  }

  getSound = type => {
    if (type === 0) {
      return 'shiftStart';
    } else if (type === 1) {
      return 'receiptPrinterOffline';
    } else if (type === 2) {
      return 'driverOffline';
    } else if (type === 3) {
      return 'orderDelivered';
    } else if (type === 4) {
      return 'arrivingToStore';
    } else if (type === 5) {
      return 'arrivedInStore';
    } else {
      return 'clockedOut';
    }
  };

  removeOldNotifications = () => {
    if (this.state.notifications) {
      let notifications = cloneDeep(this.state.notifications);
      let notificationsToBeDeleted = [];
      notifications.forEach(notification => {
        if (
          this.isOlderThenOneMinute(notification.dateTimestamp, notification)
        ) {
          notification.class = `${this.getContainerClass(notification)}`;
          notificationsToBeDeleted.push(notification);
        } else {
          notification.class = `${this.getContainerClass(notification)} show`;
        }
      });
      this.setState(
        {
          notifications: notifications,
        },
        () => {
          //console.log('delete', this.state.notifications);
          this.props.deleteNotificationsHandler(notificationsToBeDeleted);
        }
      );
    }
  };

  getContainerClass = notification => {
    if (notification.type === 1)
      return 'info-box notification warning-message-box';
    else if (notification.type === 2)
      return 'info-box notification red driver-offline';
    else if (notification.type === 3)
      return 'info-box notification blue order-delivered';
    else if (notification.type === 4)
      return 'info-box notification orange returning-to-store';
    else if (notification.type === 5 || notification.type === 0)
      return 'info-box notification green arrived-in-store';
    else if (notification.type === 6)
      return 'info-box notification red returning-to-store';
  };

  getClass = notification => {
    if (notification.type === 0) {
      return 'icon icon-shop';
    } else if (notification.type === 1) return '';
    else if (notification.type === 2) return 'icon icon-warning';
    else if (notification.type === 3) return 'icon icon-checkmark-circle-big';
    else if (notification.type === 4) return 'icon icon-location-arrow';
    else if (notification.type === 5) return 'icon icon-shop';
    else if (notification.type === 6) return 'icon icon-shop';
  };

  handleSongFinishedPlaying = () => {
    this.setState({
      soundToPlay: '',
    });
  };

  getLastSeen = notif => {
    return <TimeAgo date={notif.dateTimestamp} />;
  };

  render() {
    return (
      <div className='sidebar right'>
        {this.state.soundToPlay === 'shiftStart' && (
          <Sound
            url={arrived}
            playStatus={Sound.status.PLAYING}
            onFinishedPlaying={this.handleSongFinishedPlaying}
            volume={100}
          />
        )}
        {this.state.soundToPlay === 'receiptPrinterOffline' && (
          <Sound
            url={InternetIssueDriverDisconnected}
            playStatus={Sound.status.PLAYING}
            onFinishedPlaying={this.handleSongFinishedPlaying}
          />
        )}
        {this.state.soundToPlay === 'driverOffline' && (
          <Sound
            url={InternetIssueDriverDisconnected}
            playStatus={Sound.status.PLAYING}
            onFinishedPlaying={this.handleSongFinishedPlaying}
          />
        )}
        {this.state.soundToPlay === 'orderDelivered' && (
          <Sound
            url={OrderDelivered}
            playStatus={Sound.status.PLAYING}
            onFinishedPlaying={this.handleSongFinishedPlaying}
          />
        )}
        {this.state.soundToPlay === 'arrivingToStore' && (
          <Sound
            url={ReturningToStore}
            playStatus={Sound.status.PLAYING}
            onFinishedPlaying={this.handleSongFinishedPlaying}
          />
        )}
        {this.state.soundToPlay === 'arrivedInStore' && (
          <Sound
            url={arrived}
            playStatus={Sound.status.PLAYING}
            onFinishedPlaying={this.handleSongFinishedPlaying}
          />
        )}
        {this.state.soundToPlay === 'clockedOut' && (
          <Sound
            url={DriverSignedOut}
            playStatus={Sound.status.PLAYING}
            onFinishedPlaying={this.handleSongFinishedPlaying}
          />
        )}
        {this.state.notifications.map((m, index) => (
          <div key={m._id + index * Math.random()} className={m.class}>
            <div className='box-header'>
              <h2 className='box-name'>{m.title}</h2>
              <span className={this.getClass(m)} />
            </div>
            <div className='box-body'>
              <h3 className='driver-name'>{m.name}</h3>
              <p className='time-status'>
                {m.type === 2 ? 'Last seen ' : null}
                {m.type === 2 ? this.getLastSeen(m) : m.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

DriverNotificationBoxes.propTypes = {
  notifications: PropTypes.any,
  deleteNotificationsHandler: PropTypes.any,
};

export default DriverNotificationBoxes;
