const getCurrentTimestampFun = () => {
  var now = new Date();
  return now.getTime();
};
const getTimestampStartOfTheDayFun = () => {
  var now = new Date();
  now.setHours(0, 0, 0, 0);
  return now.getTime();
};

const getTimestampEndOfTheDayFun = () => {
  var now = new Date();
  now.setHours(23, 59, 59, 999);
  return now.getTime();
};

const getCurrentTimestamp60Fun = () => {
  var now = new Date();
  return now.getTime() - 60000;
};

export const getCurrentTimestamp = getCurrentTimestampFun;
export const getCurrent60Timestamp = getCurrentTimestamp60Fun;
export const getTimestampStartOfTheDay = getTimestampStartOfTheDayFun;
export const getTimestampEndOfTheDay = getTimestampEndOfTheDayFun;
