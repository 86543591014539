import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  getCarIcon,
  getDeliveryIcon,
  getStoreIcon,
} from '../../helpers/mapIconHelper';
const L = window.L;

class LeafletDrivers extends Component {
  constructor(props) {
    super(props);
    this.map = {};
    this.markers = [];
    this.routes = [];
    this.delivery_markers = [];
    this.timeLeft = [];
  }

  onSizeChange = () => {
    setTimeout(() => {
      this.map.invalidateSize({
        animate: true,
        noMoveStart: true,
      });
    }, 1000);
    var markerArray = [];
    markerArray.push(this.store);
    this.props.drivers.forEach(d => {
      if (d.status !== 0) {
        markerArray.push(this.markers[d._id]);
      }
      if (d.orders.length >= 1) {
        d.orders.forEach(ods => {
          if (
            ods.orderStatus === '3' ||
            ods.orderStatus === '12' ||
            ods.orderStatus === '13'
          ) {
            markerArray.push(this.delivery_markers[ods._id]);
          }
        });
      }
    });

    // Fitbound map to cover all markers
    var group = L.featureGroup(markerArray);
    this.map.invalidateSize({ animate: true });
    this.map.setView(group.getBounds().getCenter(), this.map.getZoom(), {
      //padding: [150, 150],
      //paddingBottomRight: [150, 150],
      maxZoom: 16,
      animate: true,
    });
  };
  componentDidMount() {
    window.addEventListener('resize', this.onSizeChange.bind(this));
    if (
      this.props &&
      this.props.store &&
      this.props.store.addressLocation &&
      this.props.drivers
    ) {
      //Load map and add store marker to map
      L.mapbox.accessToken =
        'pk.eyJ1Ijoid2lsbG9yaWVudCIsImEiOiJjanZ6eDRqMXMwNTI4NDhvbmVsbjhhdmhzIn0.SXJqaMi8L08EwIHZf7pRIg';
      this.map = L.mapbox
        .map('map', null, {
          scrollwheel: false,
          navigationControl: false,
          mapTypeControl: false,
          scaleControl: false,
          draggable: false,
          zoomControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          maxZoom: 16,
          scrollWheelZoom: false,
          zoomAnimation: false,
          keyboard: false,
          dragging: false,
          doubleClickZoom: false,
        })
        .setView(
          [
            Number(this.props.store.addressLocation.lat),
            Number(this.props.store.addressLocation.long),
          ],
          16
        )
        .addLayer(
          L.mapbox.styleLayer(
            'mapbox://styles/williamrichards/cjvc2w0fo6alc1fqxknvqjgoy',
            {
              tileSize: 512,
            }
          )
        );

      this.map.on('zoomstart', () => {
        let elements = document.querySelectorAll(
          'img[src="/static/media/red_car.fc05c128.svg"]'
        );
        let orangeCar = document.querySelectorAll(
          'img[src="/static/media/orange_car.c5c338bd.svg"]'
        );
        elements.forEach(element => {
          element.classList.add('no-marker-movement');
        });
        orangeCar.forEach(element => {
          element.classList.add('no-marker-movement');
        });
        let animationCar = document.querySelectorAll(
          '#map .leaflet-popup.leaflet-zoom-animated'
        );
        animationCar.forEach(car => {
          car.classList.add('no-marker-movement');
        });
      });

      this.map.on('zoomend', () => {
        let elements = document.querySelectorAll(
          'img[src="/static/media/red_car.fc05c128.svg"]'
        );
        let orangeCar = document.querySelectorAll(
          'img[src="/static/media/orange_car.c5c338bd.svg"]'
        );
        let animationCar = document.querySelectorAll(
          '#map .leaflet-popup.leaflet-zoom-animated'
        );
        setTimeout(() => {
          elements.forEach(element => {
            element.classList.remove('no-marker-movement');
          });
          orangeCar.forEach(element => {
            element.classList.remove('no-marker-movement');
          });
          animationCar.forEach(element => {
            element.classList.remove('no-marker-movement');
          });
        }, 2500);
      });

      this.store = L.marker(
        [
          this.props.store.addressLocation.lat,
          this.props.store.addressLocation.long,
        ],
        {
          rotationOrigin: 'center',
          icon: getStoreIcon(
            'https://s3-ap-southeast-2.amazonaws.com/lacampa/assets/S+P+I+con.svg'
          ),
        }
      ).addTo(this.map);

      //Add driver markers
      this.props.drivers.map(d => {
        if (d.previousLocation && d.currentLocation && d.status !== 0) {
          let icon = getCarIcon(d);
          let l1 = new window.google.maps.LatLng(
            d.previousLocation.lat,
            d.previousLocation.long
          );
          let l2 = new window.google.maps.LatLng(
            d.currentLocation.lat,
            d.currentLocation.long
          );
          let heading = window.google.maps.geometry.spherical.computeHeading(
            l1,
            l2
          );

          //Check for orders on the way
          let addressOnGoing = d.orders.filter(m => {
            return (
              m.orderStatus === '3' ||
              m.orderStatus === '12' ||
              m.orderStatus === '13'
            );
          });
          //If orders are on the way add driver with pop up, delivery address and route for the same.
          if (addressOnGoing.length > 0) {
            //Adding driver marker with pop up
            this.markers[d._id] = L.marker(
              [d.currentLocation.lat, d.currentLocation.long],
              { rotationOrigin: 'center', rotationAngle: heading, icon }
            )
              .bindPopup(
                `<div class="bubble-box">
            <p class="bubble">${addressOnGoing[0].address.split(',')[0]}</p>
        </div>`,
                { closeOnClick: false, autoClose: false }
              )
              .addTo(this.map);

            this.markers[d._id].openPopup();

            //Adding delivery address and route markers
            if (addressOnGoing[0].addressLocation) {
              //Delivery address
              this.delivery_markers[addressOnGoing[0]._id] = L.marker(
                [
                  addressOnGoing[0].addressLocation.lat,
                  addressOnGoing[0].addressLocation.lng,
                ],
                {
                  rotationOrigin: 'center',
                  icon: getDeliveryIcon(),
                }
              )
                .bindPopup(
                  `<div class="bubble-box">
                  <p class="bubble">${
                    addressOnGoing[0].arrivingToDestinationTime
                      ? addressOnGoing[0].arrivingToDestinationTime
                      : '0 mins'
                  }</p>
              </div>`,
                  { closeOnClick: false, autoClose: false }
                )
                .addTo(this.map);
              this.delivery_markers[addressOnGoing[0]._id].openPopup();

              //Driver's route to delivery address
              this.routes[addressOnGoing[0]._id] = L.Routing.control({
                router: L.Routing.osrmv1({
                  serviceUrl: 'https://osrm-api.nextorder.co/route/v1',
                }),
                waypoints: [
                  L.latLng(
                    Number(d.currentLocation.lat),
                    Number(d.currentLocation.long)
                  ),
                  L.latLng(
                    addressOnGoing[0].addressLocation.lat,
                    addressOnGoing[0].addressLocation.lng
                  ),
                ],
                show: true,
                waypointMode: 'snap',
                lineOptions: {
                  styles: [
                    { color: 'rgb(226, 90, 40)', opacity: 1, weight: 5 },
                  ],
                },
                createMarker: () => {
                  return false;
                },
                routeWhileDragging: true,
                reverseWaypoints: true,
                showAlternatives: false,
                useZoomParameter: true,
                autoRoute: true,
                fitSelectedRoutes: false,
                altLineOptions: {
                  styles: [
                    { color: '#f47f02', opacity: 0.15, weight: 9 },
                    { color: '#f4bb02', opacity: 0.8, weight: 6 },
                    { color: '#f7de00', opacity: 0.5, weight: 2 },
                  ],
                },
              }).addTo(this.map);
            }
          } else {
            this.markers[d._id] = L.marker(
              [d.currentLocation.lat, d.currentLocation.long],
              { rotationOrigin: 'center', rotationAngle: heading, icon }
            )
              .bindPopup(`<div></div>`, {
                closeOnClick: false,
                autoClose: false,
              })
              .addTo(this.map);

            this.markers[d._id].openPopup();
          }
        }
        return d;
      });
      this.recenterMap(this.props.drivers);
    }
  }
  componentWillReceiveProps(newProps) {
    if (newProps && newProps.store && newProps.drivers) {
      newProps.drivers.map(d => {
        if (d.previousLocation && d.currentLocation && d.status !== 0) {
          let preDriverState = this.props.drivers.filter(m => {
            return m._id === d._id;
          });
          let pD = {};
          if (preDriverState.length > 0) {
            pD = preDriverState[0];
          }
          if (this.markers[d._id]) {
            let newLatLng = new L.LatLng(
              d.currentLocation.lat,
              d.currentLocation.long
            );
            let l1 = new window.google.maps.LatLng(
              d.previousLocation.lat,
              d.previousLocation.long
            );
            let l2 = new window.google.maps.LatLng(
              d.currentLocation.lat,
              d.currentLocation.long
            );
            let heading = window.google.maps.geometry.spherical.computeHeading(
              l1,
              l2
            );
            let distance = window.google.maps.geometry.spherical.computeDistanceBetween(
              l1,
              l2
            );
            if (heading < 0) heading = heading + 360;
            if (distance > 10) this.markers[d._id].setRotationAngle(heading);
            this.markers[d._id].slideTo(newLatLng, {
              duration: 2500,
              keepAtCenter: false,
            });
            let addressOnGoing = d.orders.filter(m => {
              return (
                m.orderStatus === '3' ||
                m.orderStatus === '12' ||
                m.orderStatus === '13'
              );
            });
            if (addressOnGoing.length > 0) {
              this.markers[d._id].setPopupContent(`<div class="bubble-box">
              <p class="bubble">${addressOnGoing[0].address.split(',')[0]}</p>
          </div>`);
            } else {
              this.markers[d._id].setPopupContent(`<div></div>`);
            }
            if (pD._id && pD.status !== 2 && d.status === 2) {
              this.markers[d._id].setIcon(getCarIcon(d));
              this.markers[d._id].closePopup();
            }
          } else {
            let icon = getCarIcon(d);
            let l1 = new window.google.maps.LatLng(
              d.previousLocation.lat,
              d.previousLocation.long
            );
            let l2 = new window.google.maps.LatLng(
              d.currentLocation.lat,
              d.currentLocation.long
            );
            let heading = window.google.maps.geometry.spherical.computeHeading(
              l1,
              l2
            );
            let addressOnGoing = d.orders.filter(m => {
              return (
                m.orderStatus === '3' ||
                m.orderStatus === '12' ||
                m.orderStatus === '13'
              );
            });
            if (addressOnGoing.length > 0) {
              this.markers[d._id] = L.marker(
                [d.currentLocation.lat, d.currentLocation.long],
                { rotationOrigin: 'center', rotationAngle: heading, icon }
              )
                .bindPopup(
                  `<div class="bubble-box">
              <p class="bubble">${addressOnGoing[0].address.split(',')[0]}</p>
          </div>`,
                  { closeOnClick: false, autoClose: false }
                )
                .addTo(this.map);

              this.markers[d._id].openPopup();
            } else {
              this.markers[d._id] = L.marker(
                [d.currentLocation.lat, d.currentLocation.long],
                { rotationOrigin: 'center', rotationAngle: heading, icon }
              )
                .bindPopup(`<div></div>`, {
                  closeOnClick: false,
                  autoClose: false,
                })
                .addTo(this.map);

              this.markers[d._id].openPopup();
            }
          }
        } else {
          if (this.markers[d._id]) {
            this.markers[d._id].closePopup();
            if (this.map) {
              this.map.removeLayer(this.markers[d._id]);
            }
            delete this.markers[d._id];
          }
        }

        //Add delivery address markers and routes
        if (d.orders && d.currentLocation) {
          d.orders.forEach(ods => {
            if (
              (ods.orderStatus === '3' ||
                ods.orderStatus === '12' ||
                ods.orderStatus === '13') &&
              ods.addressLocation
            ) {
              if (!this.delivery_markers[ods._id]) {
                //Add marker
                this.delivery_markers[ods._id] = L.marker(
                  [ods.addressLocation.lat, ods.addressLocation.lng],
                  {
                    rotationOrigin: 'center',
                    icon: getDeliveryIcon(),
                  }
                )
                  .bindPopup(
                    `${
                      ods.arrivingToDestinationTime
                        ? ods.orderStatus === '12' || ods.orderStatus === '13'
                          ? `<div class="bubble-box">
                            <p class="bubble">Arrived</p></div>`
                          : ods.arrivingToDestinationTime === '0 mins'
                          ? `<div class="bubble-box">
                            <p class="bubble">1 min</p></div>`
                          : `<div class="bubble-box">
                            <p class="bubble">${
                              ods.arrivingToDestinationTime
                            }</p></div>`
                        : `<div></div>`
                    }
                  `,
                    { closeOnClick: false, autoClose: false }
                  )
                  .addTo(this.map);
                this.delivery_markers[ods._id].openPopup();
              } else {
                if (
                  ods.orderStatus === '3' &&
                  ods.arrivingToDestinationTime !== '0 mins'
                ) {
                  this.delivery_markers[ods._id].setPopupContent(`${
                    ods.arrivingToDestinationTime
                      ? ods.orderStatus === '12' || ods.orderStatus === '13'
                        ? `<div class="bubble-box">
                      <p class="bubble">Arrived</p></div>`
                        : ods.arrivingToDestinationTime === '0 mins'
                        ? `<div class="bubble-box">
                      <p class="bubble">1 min</p></div>`
                        : `<div class="bubble-box">
                      <p class="bubble">${
                        ods.arrivingToDestinationTime
                      }</p></div>`
                      : `<div></div>`
                  }
            `);
                }

                if (
                  ods.orderStatus === '12' ||
                  ods.orderStatus === '13' ||
                  ods.arrivingToDestinationTime === '0 mins'
                ) {
                  this.delivery_markers[ods._id].setPopupContent(`<div>
              </div>`);
                }
              }
              if (!this.routes[ods._id]) {
                //Driver's route to delivery address
                this.routes[ods._id] = L.Routing.control({
                  router: L.Routing.osrmv1({
                    serviceUrl: 'https://osrm-api.nextorder.co/route',
                  }),
                  waypoints: [
                    L.latLng(
                      Number(d.currentLocation.lat),
                      Number(d.currentLocation.long)
                    ),
                    L.latLng(ods.addressLocation.lat, ods.addressLocation.lng),
                  ],
                  show: true,
                  waypointMode: 'snap',
                  lineOptions: {
                    styles: [
                      { color: 'rgb(226, 90, 40)', opacity: 1, weight: 5 },
                    ],
                  },
                  createMarker: () => {
                    return false;
                  },
                  routeWhileDragging: true,
                  reverseWaypoints: true,
                  showAlternatives: false,
                  useZoomParameter: true,
                  autoRoute: true,
                  fitSelectedRoutes: false,
                  altLineOptions: {
                    styles: [
                      { color: '#f47f02', opacity: 0.15, weight: 9 },
                      { color: '#f4bb02', opacity: 0.8, weight: 6 },
                      { color: '#f7de00', opacity: 0.5, weight: 2 },
                    ],
                  },
                }).addTo(this.map);
              } else {
                setTimeout(() => {
                  this.routes[ods._id] &&
                    this.routes[ods._id]
                      .getPlan()
                      .setWaypoints([
                        L.latLng(
                          Number(d.currentLocation.lat),
                          Number(d.currentLocation.long)
                        ),
                        L.latLng(
                          ods.addressLocation.lat,
                          ods.addressLocation.lng
                        ),
                      ]);
                }, 2500);
              }
            } else {
              if (this.delivery_markers[ods._id]) {
                //Remove marker
                if (this.map) {
                  this.map.removeLayer(this.delivery_markers[ods._id]);
                }
                delete this.delivery_markers[ods._id];
              }
              if (this.routes[ods._id]) {
                //Remove marker
                this.map.removeControl(this.routes[ods._id]);
                delete this.routes[ods._id];
              }
            }
          });
        }
        return d;
      });
      this.recenterMap(newProps.drivers);
    }
  }
  recenterMap = drivers => {
    var markerArray = [];
    // Only insert it if all the drivers are in store
    markerArray.push(this.store);
    drivers.forEach(d => {
      if (
        d.status !== 0 &&
        d.previousLocation &&
        d.currentLocation &&
        this.markers[d._id]
      ) {
        markerArray.push(this.markers[d._id]);
      }
      if (d.orders.length >= 1) {
        d.orders.forEach(ods => {
          if (
            (ods.orderStatus === '3' ||
              ods.orderStatus === '12' ||
              ods.orderStatus === '13') &&
            this.delivery_markers[ods._id]
          ) {
            markerArray.push(this.delivery_markers[ods._id]);
          }
        });
      }
    });
    //console.log('markerArray', markerArray);
    //markerArray = _.compact(markerArray);
    // Fitbound map to cover all markers
    var group = L.featureGroup(markerArray);
    this.map.setView(group.getBounds().getCenter(), this.map.getZoom(), {
      padding: [50, 50],
      maxZoom: 16,
      animate: true,
      duration: 2,
    });
    if (markerArray.length === 1) {
      this.map.fitBounds(group.getBounds(), {
        paddingTopLeft: [50, 50],
        paddingBottomRight: [60, 60],
        maxZoom: 16,
        animate: true,
        duration: 2,
      });
    } else {
      this.map.fitBounds(group.getBounds(), {
        paddingTopLeft: [450, 150],
        paddingBottomRight: [60, 60],
        maxZoom: 16,
        animate: true,
        duration: 2,
      });
    }
  };

  render() {
    return <div id='map' ref='map' />;
  }
}

LeafletDrivers.propTypes = {
  store: PropTypes.any,
  drivers: PropTypes.any,
};

export default LeafletDrivers;
