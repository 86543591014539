import { combineReducers } from 'redux';
import inStoreReducer from './containers/InStore/reducer';
import loginReducer from './containers/Login/reducer';

const rootReducer = combineReducers({
  inStoreReducer,
  loginReducer,
});

export default rootReducer;
