import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Login from './containers/Login';
import InStore from './containers/InStore';

export default () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/' component={Login} />
        <Route path='/restaurant/login' component={Login} />
        <Route path='/restaurant/instore' component={InStore} />
      </Switch>
    </BrowserRouter>
  );
};
