import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LeafletDrivers from '../../components/LeafletDrivers';
import 'leaflet-rotatedmarker';
import '../../assets/css/leafletIn.css';
import '../../assets/js/leafletGoogle';
import '../../assets/js/leafletMarkerSlideTo';
import '../../assets/js/Routing';

class DriverMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drivers: [],
    };
  }
  getDriverStatus = e => {
    switch (e) {
      case '0':
        return 'Idle';
      case '1':
        return 'On the way';
      case '2':
        return 'Arriving';
      case '3':
        return 'Arrived';
      case '4':
        return 'Delivered';
      case '5':
        return 'Returning to store';
      default:
        return '';
    }
  };
  render() {
    return (
      <React.Fragment>
        {this.props.drivers && this.props.store ? (
          <LeafletDrivers
            drivers={this.props.drivers}
            store={this.props.store}
          />
        ) : (
          <div
            style={{ padding: '50px' }}
            className='be-loading be-loading-active'
          >
            <div className='be-spinner'>
              <svg
                width='40px'
                height='40px'
                viewBox='0 0 66 66'
                xmlns='http://www.w3.org/2000/svg'
              >
                <circle
                  fill='none'
                  strokeWidth='4'
                  strokeLinecap='round'
                  cx='33'
                  cy='33'
                  r='30'
                  className='circle'
                />
              </svg>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

DriverMap.propTypes = {
  store: PropTypes.any,
  drivers: PropTypes.any,
};

export default DriverMap;
