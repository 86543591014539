import ActionTypes from './constants';
export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.FetchActiveDrivers:
      return { ...state, drivers: payload.drivers };
    case ActionTypes.FetchActiveDriverShifts:
      return { ...state, driverShifts: payload.driverShifts };
    case ActionTypes.FetchActiveDriverRuns:
      return { ...state, driverRuns: payload.driverRuns };
    case ActionTypes.FetchActiveDriverRunOrders:
      return { ...state, driverRunOrders: payload.driverRunOrders };
    case ActionTypes.OrderSetupSaved:
      return { ...state, orderSetup: payload.orderSetup };
    case ActionTypes.FetchStore:
      return { ...state, store: payload.store };
    case ActionTypes.FetchNotifications:
      return { ...state, notifications: payload.notifications };
    case ActionTypes.FetchAllShifts:
      return { ...state, allShifts: payload.allShifts };
    default:
      return state;
  }
};
